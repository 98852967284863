
import React, { useRef, useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Pagina } from '../../componentes/pagina';
export default function Politicas() {
      
    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);

      const { pdfDocument } = usePdf({
        file: './pdf/politica-de-direitos-humanos-2024-marechal.pdf',
        page,
        canvasRef,
      });
  return (
    <>
    <Pagina titulo={"Politicas de Diretos Humanos"}>
    <div className="pdf">

      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav>
      
              <button               class="ui button"
 disabled={page === 1} onClick={() => setPage(page - 1)}>
                Anterior
              </button>
   
              <button
              class="ui button"
                disabled={page === pdfDocument.numPages}
                onClick={() => setPage(page + 1)}
              >
                Proximo
              </button>
       
        </nav>
      )}
     </div>


     </Pagina>
    </>
  );
}